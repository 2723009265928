import React from 'react'
import PropTypes from 'prop-types';
import Slide from '../img/v2/SLIDE.jpg';

function Hero({className}) {
    return (
        <div className={`flex flex-col relative max-h-[300px] min-h-[200px] overflow-hidden bg-gray-300 justify-center ${className}`}>
            <img src={Slide} className='w-full hidden sm:block' alt="fondo ciudad"/>
            <div className='flex-0 sm:absolute text-blue-800 text-shadow top-2 left-2 p-3 font-bold text-xl lg:top-8 lg:left-8 md:text-3xl lg:text-5xl  lg:max-w-lg xl:max-w-3xl'>
                REALIZÁ RECLAMOS, SOLICITUDES Y DENUNCIAS
            </div>
        </div>    
    );
}

Hero.propTypes = {
    className: PropTypes.string,
}

export default Hero;
