/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Direcciones from '../../componentes/Direcciones';
import { HelpCenter } from '@mui/icons-material';
import useTicketStore from '../../stores/ticket.store';
import useAltaReclamoStore from '../../stores/altareclamo.store';
import PopUp from '../../componentes/PopUp';

const AltaReclamosPaso2 = ({
    options: { 
        setMessageSnackbar,
    }
}) => {

    const {direccion, prestacion, setDireccion, resetCoords, resetDireccion, elementos, setElementos} = useTicketStore();
    const setValidator = useAltaReclamoStore(state => state.setValidator);
    
    const validator = (ticket) => {
        if (!ticket.direccion.validada) {
            if ('COLECTIVO' == ticket.prestacion.geolocation && ticket.direccion.linea == '') {
                setMessageSnackbar('Debe completar la linea de colectivos para avanzar', 'error');
                return false;
            }
            if ('COLECTIVO' != ticket.prestacion.geolocation) {
                setMessageSnackbar('Debe completar y validar la dirección para avanzar', 'error');
                return false;
            }
        } else {
            if ('LUMINARIA' == ticket.prestacion.geolocation && ticket.direccion.elemento == '') {
                setMessageSnackbar('Debe hacer click sobre la luminaria en el mapa antes de continuar', 'error');
                return false;
            }
            if ('SEMAFORO' == ticket.prestacion.geolocation && ticket.direccion.elemento == '') {
                setMessageSnackbar('Debe hacer click sobre el semáforo en el mapa antes de avanzar', 'error');
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        setValidator(2, validator);        
    }, []);

    return (
        <>
            <div className='font-bold text-3xl text-[rgb(11,59,108)] mb-5'>Dirección - {prestacion.name}</div>

            <div className='w-100 p-5 flex flex-row rounded-lg border-2 align-middle'>
                <div className='flex-0 mr-5'><HelpCenter fontSize='large' /></div>
                <div className='flex-1 text-gray-500'>
                    Ubicar el problema. Puede arrastrar el indicador al lugar exacto si la calle y altura no son exactas. 
                    En el caso de Luminarias y Semáforos debe hacer click sobre el del problema antes de continuar. 
                    Luego haga click sobre el botón <b>Siguiente</b> al pie de este formulario
                </div>
            </div>

            <div className='w-full mt-5 mb-5'>
                <Direcciones
                    value={direccion}
                    elementos={elementos}
                    type={prestacion.geolocation}
                    onChange={setDireccion}
                    options={{ setMessageSnackbar, resetCoords, resetDireccion, setElementos }}
                />
            </div>

            {prestacion.prerequisite!=='' && <PopUp
                title='Importante'
                message={prestacion.prerequisite}
            />}
        </>);
};

AltaReclamosPaso2.propTypes = {
    options: PropTypes.object.isRequired,
};

export default AltaReclamosPaso2;
